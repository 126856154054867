<template>
  <el-tooltip class="item" effect="dark" :content="colorDesc" placement="top">
    <span class="colored-item" :style="{ backgroundColor: color }"></span>
  </el-tooltip>
</template>
<script>
export default {
  name: 'camera-status',
  props: ['color', 'colorDesc'],
  data: function () {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="stylus">
.colored-item {
  display: inline-block;
  box-sizing: border-box;
  transform: translateY(2px);
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  line-height: 2rem;
  border: 1px solid rgba(0, 0, 0, .1);
}
</style>
